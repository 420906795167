import React from 'react';
import { Container, Row, Col, Jumbotron } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import main from "./main.md"
import pubs from "./pubs.md"
import press from "./press.md"
import summary from "./summary.md"
import large_header from "./large_header.png"
import Breadcrumb from "../../commons/Breadcrumb"
import gfm from 'remark-gfm'

import {ComposedChart, Area, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer} from 'recharts';

const I_data =[{'t': 0, 'I': 20, 'I_CI': [10, 30]},
{'t': 1, 'I': 21, 'I_CI': [11, 31]},
{'t': 2, 'I': 23, 'I_CI': [13, 33]},
{'t': 3, 'I': 24, 'I_CI': [14, 34]},
{'t': 4, 'I': 29, 'I_CI': [19, 39]},
{'t': 5, 'I': 31, 'I_CI': [21, 41]},
{'t': 6, 'I': 36, 'I_CI': [26, 46]},
{'t': 7, 'I': 49, 'I_CI': [39, 59]},
{'t': 8, 'I': 35, 'I_CI': [25, 45]},
{'t': 9, 'I': 33, 'I_CI': [23, 43]},
{'t': 10, 'I': 36, 'I_CI': [26, 46]},
{'t': 11, 'I': 27, 'I_CI': [17, 37]},
{'t': 12, 'I': 33, 'I_CI': [23, 43]},
{'t': 13, 'I': 29, 'I_CI': [19, 39]},
{'t': 14, 'I': 28, 'I_CI': [18, 38]},
{'t': 15, 'I': 20, 'I_CI': [10, 30]},
{'t': 16, 'I': 23, 'I_CI': [13, 33]},
{'t': 17, 'I': 28, 'I_CI': [18, 38]},
{'t': 18, 'I': 29, 'I_CI': [19, 39]},
{'t': 19, 'I': 35, 'I_CI': [25, 45]},
{'t': 20, 'I': 43, 'I_CI': [33, 53]},
{'t': 21, 'I': 45, 'I_CI': [35, 55]},
{'t': 22, 'I': 47, 'I_CI': [37, 57]},
{'t': 23, 'I': 49, 'I_CI': [39, 59]},
{'t': 24, 'I': 46, 'I_CI': [36, 56]},
{'t': 25, 'I': 55, 'I_CI': [45, 65]},
{'t': 26, 'I': 57, 'I_CI': [47, 67]},
{'t': 27, 'I': 62, 'I_CI': [52, 72]},
{'t': 28, 'I': 53, 'I_CI': [43, 63]},
{'t': 29, 'I': 60, 'I_CI': [50, 70]},
{'t': 30, 'I': 63, 'I_CI': [53, 73]},
{'t': 31, 'I': 64, 'I_CI': [54, 74]},
{'t': 32, 'I': 70, 'I_CI': [60, 80]},
{'t': 33, 'I': 68, 'I_CI': [58, 78]},
{'t': 34, 'I': 52, 'I_CI': [42, 62]},
{'t': 35, 'I': 47, 'I_CI': [37, 57]},
{'t': 36, 'I': 55, 'I_CI': [45, 65]},
{'t': 37, 'I': 72, 'I_CI': [62, 82]},
{'t': 38, 'I': 66, 'I_CI': [56, 76]},
{'t': 39, 'I': 63, 'I_CI': [53, 73]},
{'t': 40, 'I': 52, 'I_CI': [42, 62]},
{'t': 41, 'I': 53, 'I_CI': [43, 63]},
{'t': 42, 'I': 68, 'I_CI': [58, 78]},
{'t': 43, 'I': 90, 'I_CI': [80, 100]},
{'t': 44, 'I': 108, 'I_CI': [98, 118]},
{'t': 45, 'I': 103, 'I_CI': [93, 113]},
{'t': 46, 'I': 97, 'I_CI': [87, 107]},
{'t': 47, 'I': 97, 'I_CI': [87, 107]},
{'t': 48, 'I': 97, 'I_CI': [87, 107]},
{'t': 49, 'I': 80, 'I_CI': [70, 90]},
{'t': 50, 'I': 69, 'I_CI': [59, 79]},
{'t': 51, 'I': 67, 'I_CI': [57, 77]},
{'t': 52, 'I': 62, 'I_CI': [52, 72]},
{'t': 53, 'I': 69, 'I_CI': [59, 79]},
{'t': 54, 'I': 63, 'I_CI': [53, 73]},
{'t': 55, 'I': 71, 'I_CI': [61, 81]},
{'t': 56, 'I': 69, 'I_CI': [59, 79]},
{'t': 57, 'I': 89, 'I_CI': [79, 99]},
{'t': 58, 'I': 86, 'I_CI': [76, 96]},
{'t': 59, 'I': 78, 'I_CI': [68, 88]},
{'t': 60, 'I': 98, 'I_CI': [88, 108]},
{'t': 61, 'I': 78, 'I_CI': [68, 88]},
{'t': 62, 'I': 74, 'I_CI': [64, 84]},
{'t': 63, 'I': 85, 'I_CI': [75, 95]},
{'t': 64, 'I': 68, 'I_CI': [58, 78]},
{'t': 65, 'I': 59, 'I_CI': [49, 69]},
{'t': 66, 'I': 53, 'I_CI': [43, 63]},
{'t': 67, 'I': 51, 'I_CI': [41, 61]},
{'t': 68, 'I': 34, 'I_CI': [24, 44]},
{'t': 69, 'I': 29, 'I_CI': [19, 39]},
{'t': 70, 'I': 27, 'I_CI': [17, 37]},
{'t': 71, 'I': 24, 'I_CI': [14, 34]},
{'t': 72, 'I': 26, 'I_CI': [16, 36]},
{'t': 73, 'I': 20, 'I_CI': [10, 30]},
{'t': 74, 'I': 21, 'I_CI': [11, 31]},
{'t': 75, 'I': 20, 'I_CI': [10, 30]},
{'t': 76, 'I': 18, 'I_CI': [8, 28]},
{'t': 77, 'I': 9, 'I_CI': [0, 19]},
{'t': 78, 'I': 10, 'I_CI': [0, 20]},
{'t': 79, 'I': 16, 'I_CI': [6, 26]},
{'t': 80, 'I': 13, 'I_CI': [3, 23]},
{'t': 81, 'I': 22, 'I_CI': [12, 32]},
{'t': 82, 'I': 37, 'I_CI': [27, 47]},
{'t': 83, 'I': 46, 'I_CI': [36, 56]},
{'t': 84, 'I': 38, 'I_CI': [28, 48]},
{'t': 85, 'I': 43, 'I_CI': [33, 53]},
{'t': 86, 'I': 58, 'I_CI': [48, 68]},
{'t': 87, 'I': 81, 'I_CI': [71, 91]},
{'t': 88, 'I': 102, 'I_CI': [92, 112]},
{'t': 89, 'I': 102, 'I_CI': [92, 112]},
{'t': 90, 'I': 116, 'I_CI': [106, 126]},
{'t': 91, 'I': 120, 'I_CI': [110, 130]},
{'t': 92, 'I': 122, 'I_CI': [112, 132]},
{'t': 93, 'I': 126, 'I_CI': [116, 136]},
{'t': 94, 'I': 153, 'I_CI': [143, 163]},
{'t': 95, 'I': 163, 'I_CI': [153, 173]},
{'t': 96, 'I': 171, 'I_CI': [161, 181]},
{'t': 97, 'I': 174, 'I_CI': [164, 184]},
{'t': 98, 'I': 186, 'I_CI': [176, 196]},
{'t': 99, 'I': 197, 'I_CI': [187, 207]},
{'t': 100, 'I': 228, 'I_CI': [218, 238]},
{'t': 101, 'I': 223, 'I_CI': [213, 233]},
{'t': 102, 'I': 242, 'I_CI': [232, 252]},
{'t': 103, 'I': 255, 'I_CI': [245, 265]},
{'t': 104, 'I': 263, 'I_CI': [253, 273]},
{'t': 105, 'I': 282, 'I_CI': [272, 292]},
{'t': 106, 'I': 318, 'I_CI': [308, 328]},
{'t': 107, 'I': 317, 'I_CI': [307, 327]},
{'t': 108, 'I': 310, 'I_CI': [300, 320]},
{'t': 109, 'I': 332, 'I_CI': [322, 342]},
{'t': 110, 'I': 346, 'I_CI': [336, 356]},
{'t': 111, 'I': 338, 'I_CI': [328, 348]},
{'t': 112, 'I': 350, 'I_CI': [340, 360]},
{'t': 113, 'I': 369, 'I_CI': [359, 379]},
{'t': 114, 'I': 399, 'I_CI': [389, 409]},
{'t': 115, 'I': 411, 'I_CI': [401, 421]},
{'t': 116, 'I': 395, 'I_CI': [385, 405]},
{'t': 117, 'I': 455, 'I_CI': [445, 465]},
{'t': 118, 'I': 481, 'I_CI': [471, 491]},
{'t': 119, 'I': 505, 'I_CI': [495, 515]},
{'t': 120, 'I': 540, 'I_CI': [530, 550]},
{'t': 121, 'I': 548, 'I_CI': [538, 558]},
{'t': 122, 'I': 578, 'I_CI': [568, 588]},
{'t': 123, 'I': 619, 'I_CI': [609, 629]},
{'t': 124, 'I': 633, 'I_CI': [623, 643]},
{'t': 125, 'I': 697, 'I_CI': [687, 707]},
{'t': 126, 'I': 747, 'I_CI': [737, 757]},
{'t': 127, 'I': 787, 'I_CI': [777, 797]},
{'t': 128, 'I': 865, 'I_CI': [855, 875]},
{'t': 129, 'I': 910, 'I_CI': [900, 920]},
{'t': 130, 'I': 1017, 'I_CI': [1007, 1027]},
{'t': 131, 'I': 1113, 'I_CI': [1103, 1123]},
{'t': 132, 'I': 1201, 'I_CI': [1191, 1211]},
{'t': 133, 'I': 1294, 'I_CI': [1284, 1304]},
{'t': 134, 'I': 1393, 'I_CI': [1383, 1403]},
{'t': 135, 'I': 1522, 'I_CI': [1512, 1532]},
{'t': 136, 'I': 1652, 'I_CI': [1642, 1662]},
{'t': 137, 'I': 1711, 'I_CI': [1701, 1721]},
{'t': 138, 'I': 1821, 'I_CI': [1811, 1831]},
{'t': 139, 'I': 1986, 'I_CI': [1976, 1996]},
{'t': 140, 'I': 2141, 'I_CI': [2131, 2151]},
{'t': 141, 'I': 2244, 'I_CI': [2234, 2254]},
{'t': 142, 'I': 2368, 'I_CI': [2358, 2378]},
{'t': 143, 'I': 2489, 'I_CI': [2479, 2499]},
{'t': 144, 'I': 2604, 'I_CI': [2594, 2614]},
{'t': 145, 'I': 2749, 'I_CI': [2739, 2759]},
{'t': 146, 'I': 2893, 'I_CI': [2883, 2903]},
{'t': 147, 'I': 3058, 'I_CI': [3048, 3068]},
{'t': 148, 'I': 3358, 'I_CI': [3348, 3368]},
{'t': 149, 'I': 3499, 'I_CI': [3489, 3509]},
{'t': 150, 'I': 3579, 'I_CI': [3569, 3589]},
{'t': 151, 'I': 3695, 'I_CI': [3685, 3705]},
{'t': 152, 'I': 3584, 'I_CI': [3574, 3594]},
{'t': 153, 'I': 3443, 'I_CI': [3433, 3453]},
{'t': 154, 'I': 3270, 'I_CI': [3260, 3280]},
{'t': 155, 'I': 3146, 'I_CI': [3136, 3156]},
{'t': 156, 'I': 3098, 'I_CI': [3088, 3108]},
{'t': 157, 'I': 2975, 'I_CI': [2965, 2985]},
{'t': 158, 'I': 2852, 'I_CI': [2842, 2862]},
{'t': 159, 'I': 2760, 'I_CI': [2750, 2770]},
{'t': 160, 'I': 2662, 'I_CI': [2652, 2672]},
{'t': 161, 'I': 2476, 'I_CI': [2466, 2486]},
{'t': 162, 'I': 2368, 'I_CI': [2358, 2378]},
{'t': 163, 'I': 2375, 'I_CI': [2365, 2385]},
{'t': 164, 'I': 2288, 'I_CI': [2278, 2298]},
{'t': 165, 'I': 2150, 'I_CI': [2140, 2160]},
{'t': 166, 'I': 2085, 'I_CI': [2075, 2095]},
{'t': 167, 'I': 2003, 'I_CI': [1993, 2013]},
{'t': 168, 'I': 1929, 'I_CI': [1919, 1939]},
{'t': 169, 'I': 1721, 'I_CI': [1711, 1731]},
{'t': 170, 'I': 1638, 'I_CI': [1628, 1648]},
{'t': 171, 'I': 1561, 'I_CI': [1551, 1571]},
{'t': 172, 'I': 1491, 'I_CI': [1481, 1501]},
{'t': 173, 'I': 1413, 'I_CI': [1403, 1423]},
{'t': 174, 'I': 1344, 'I_CI': [1334, 1354]},
{'t': 175, 'I': 1235, 'I_CI': [1225, 1245]},
{'t': 176, 'I': 1150, 'I_CI': [1140, 1160]},
{'t': 177, 'I': 1085, 'I_CI': [1075, 1095]},
{'t': 178, 'I': 1037, 'I_CI': [1027, 1047]},
{'t': 179, 'I': 1049, 'I_CI': [1039, 1059]},
{'t': 180, 'I': 1049, 'I_CI': [1039, 1059]},
{'t': 181, 'I': 955, 'I_CI': [945, 965]},
{'t': 182, 'I': 894, 'I_CI': [884, 904]},
{'t': 183, 'I': 805, 'I_CI': [795, 815]},
{'t': 184, 'I': 738, 'I_CI': [728, 748]},
{'t': 185, 'I': 729, 'I_CI': [719, 739]},
{'t': 186, 'I': 662, 'I_CI': [652, 672]},
{'t': 187, 'I': 562, 'I_CI': [552, 572]},
{'t': 188, 'I': 527, 'I_CI': [517, 537]},
{'t': 189, 'I': 492, 'I_CI': [482, 502]},
{'t': 190, 'I': 459, 'I_CI': [449, 469]},
{'t': 191, 'I': 461, 'I_CI': [451, 471]},
{'t': 192, 'I': 416, 'I_CI': [406, 426]},
{'t': 193, 'I': 386, 'I_CI': [376, 396]},
{'t': 194, 'I': 370, 'I_CI': [360, 380]},
{'t': 195, 'I': 307, 'I_CI': [297, 317]},
{'t': 196, 'I': 244, 'I_CI': [234, 254]},
{'t': 197, 'I': 240, 'I_CI': [230, 250]},
{'t': 198, 'I': 234, 'I_CI': [224, 244]},
{'t': 199, 'I': 212, 'I_CI': [202, 222]},
{'t': 200, 'I': 163, 'I_CI': [153, 173]},
{'t': 201, 'I': 155, 'I_CI': [145, 165]},
{'t': 202, 'I': 153, 'I_CI': [143, 163]},
{'t': 203, 'I': 138, 'I_CI': [128, 148]},
{'t': 204, 'I': 122, 'I_CI': [112, 132]},
{'t': 205, 'I': 120, 'I_CI': [110, 130]},
{'t': 206, 'I': 101, 'I_CI': [91, 111]},
{'t': 207, 'I': 93, 'I_CI': [83, 103]},
{'t': 208, 'I': 85, 'I_CI': [75, 95]},
{'t': 209, 'I': 72, 'I_CI': [62, 82]},
{'t': 210, 'I': 60, 'I_CI': [50, 70]},
{'t': 211, 'I': 54, 'I_CI': [44, 64]},
{'t': 212, 'I': 56, 'I_CI': [46, 66]},
{'t': 213, 'I': 56, 'I_CI': [46, 66]},
{'t': 214, 'I': 45, 'I_CI': [35, 55]},
{'t': 215, 'I': 35, 'I_CI': [25, 45]},
{'t': 216, 'I': 37, 'I_CI': [27, 47]},
{'t': 217, 'I': 33, 'I_CI': [23, 43]},
{'t': 218, 'I': 28, 'I_CI': [18, 38]},
{'t': 219, 'I': 33, 'I_CI': [23, 43]},
{'t': 220, 'I': 25, 'I_CI': [15, 35]},
{'t': 221, 'I': 16, 'I_CI': [6, 26]},
{'t': 222, 'I': 8, 'I_CI': [0, 18]},
{'t': 223, 'I': 2, 'I_CI': [0, 12]},
{'t': 224, 'I': 0, 'I_CI': [0, 10]},
{'t': 225, 'I': 0, 'I_CI': [0, 10]}]

const Rt_data = [{'t': 0, 'Rt': 1.53, 'CI': [3.63, 0]},
{'t': 1, 'Rt': 1.43, 'CI': [2.98, 0]},
{'t': 2, 'Rt': 1.49, 'CI': [2.76, 0.06]},
{'t': 3, 'Rt': 1.53, 'CI': [2.61, 0.33]},
{'t': 4, 'Rt': 1.53, 'CI': [2.48, 0.5]},
{'t': 5, 'Rt': 1.68, 'CI': [2.51, 0.78]},
{'t': 6, 'Rt': 1.53, 'CI': [2.28, 0.72]},
{'t': 7, 'Rt': 1.29, 'CI': [1.99, 0.54]},
{'t': 8, 'Rt': 1.26, 'CI': [1.92, 0.56]},
{'t': 9, 'Rt': 1.19, 'CI': [1.82, 0.52]},
{'t': 10, 'Rt': 1.15, 'CI': [1.75, 0.51]},
{'t': 11, 'Rt': 1.15, 'CI': [1.73, 0.54]},
{'t': 12, 'Rt': 1.11, 'CI': [1.67, 0.52]},
{'t': 13, 'Rt': 1.05, 'CI': [1.59, 0.48]},
{'t': 14, 'Rt': 1.02, 'CI': [1.55, 0.46]},
{'t': 15, 'Rt': 1.06, 'CI': [1.58, 0.52]},
{'t': 16, 'Rt': 1.09, 'CI': [1.59, 0.56]},
{'t': 17, 'Rt': 1.12, 'CI': [1.6, 0.61]},
{'t': 18, 'Rt': 1.17, 'CI': [1.64, 0.68]},
{'t': 19, 'Rt': 1.2, 'CI': [1.65, 0.72]},
{'t': 20, 'Rt': 1.2, 'CI': [1.64, 0.74]},
{'t': 21, 'Rt': 1.2, 'CI': [1.63, 0.76]},
{'t': 22, 'Rt': 1.19, 'CI': [1.6, 0.76]},
{'t': 23, 'Rt': 1.19, 'CI': [1.59, 0.78]},
{'t': 24, 'Rt': 1.21, 'CI': [1.6, 0.81]},
{'t': 25, 'Rt': 1.22, 'CI': [1.59, 0.83]},
{'t': 26, 'Rt': 1.2, 'CI': [1.56, 0.82]},
{'t': 27, 'Rt': 1.18, 'CI': [1.53, 0.81]},
{'t': 28, 'Rt': 1.19, 'CI': [1.54, 0.84]},
{'t': 29, 'Rt': 1.19, 'CI': [1.53, 0.85]},
{'t': 30, 'Rt': 1.2, 'CI': [1.52, 0.86]},
{'t': 31, 'Rt': 1.19, 'CI': [1.51, 0.87]},
{'t': 32, 'Rt': 1.15, 'CI': [1.46, 0.83]},
{'t': 33, 'Rt': 1.11, 'CI': [1.41, 0.79]},
{'t': 34, 'Rt': 1.11, 'CI': [1.41, 0.8]},
{'t': 35, 'Rt': 1.15, 'CI': [1.44, 0.84]},
{'t': 36, 'Rt': 1.16, 'CI': [1.44, 0.86]},
{'t': 37, 'Rt': 1.14, 'CI': [1.42, 0.85]},
{'t': 38, 'Rt': 1.11, 'CI': [1.39, 0.83]},
{'t': 39, 'Rt': 1.09, 'CI': [1.37, 0.81]},
{'t': 40, 'Rt': 1.11, 'CI': [1.38, 0.84]},
{'t': 41, 'Rt': 1.16, 'CI': [1.42, 0.89]},
{'t': 42, 'Rt': 1.2, 'CI': [1.46, 0.94]},
{'t': 43, 'Rt': 1.21, 'CI': [1.46, 0.95]},
{'t': 44, 'Rt': 1.18, 'CI': [1.43, 0.93]},
{'t': 45, 'Rt': 1.17, 'CI': [1.41, 0.93]},
{'t': 46, 'Rt': 1.16, 'CI': [1.4, 0.92]},
{'t': 47, 'Rt': 1.14, 'CI': [1.37, 0.9]},
{'t': 48, 'Rt': 1.11, 'CI': [1.33, 0.88]},
{'t': 49, 'Rt': 1.09, 'CI': [1.32, 0.86]},
{'t': 50, 'Rt': 1.08, 'CI': [1.3, 0.86]},
{'t': 51, 'Rt': 1.08, 'CI': [1.3, 0.86]},
{'t': 52, 'Rt': 1.08, 'CI': [1.3, 0.86]},
{'t': 53, 'Rt': 1.08, 'CI': [1.3, 0.86]},
{'t': 54, 'Rt': 1.09, 'CI': [1.3, 0.87]},
{'t': 55, 'Rt': 1.1, 'CI': [1.31, 0.89]},
{'t': 56, 'Rt': 1.11, 'CI': [1.31, 0.9]},
{'t': 57, 'Rt': 1.1, 'CI': [1.3, 0.89]},
{'t': 58, 'Rt': 1.1, 'CI': [1.3, 0.9]},
{'t': 59, 'Rt': 1.1, 'CI': [1.3, 0.9]},
{'t': 60, 'Rt': 1.08, 'CI': [1.28, 0.88]},
{'t': 61, 'Rt': 1.09, 'CI': [1.28, 0.89]},
{'t': 62, 'Rt': 1.08, 'CI': [1.27, 0.88]},
{'t': 63, 'Rt': 1.06, 'CI': [1.25, 0.87]},
{'t': 64, 'Rt': 1.05, 'CI': [1.24, 0.86]},
{'t': 65, 'Rt': 1.04, 'CI': [1.23, 0.85]},
{'t': 66, 'Rt': 1.03, 'CI': [1.22, 0.84]},
{'t': 67, 'Rt': 1.02, 'CI': [1.2, 0.83]},
{'t': 68, 'Rt': 1.01, 'CI': [1.2, 0.82]},
{'t': 69, 'Rt': 1.01, 'CI': [1.19, 0.82]},
{'t': 70, 'Rt': 1.01, 'CI': [1.19, 0.82]},
{'t': 71, 'Rt': 1.0, 'CI': [1.19, 0.82]},
{'t': 72, 'Rt': 1.0, 'CI': [1.18, 0.82]},
{'t': 73, 'Rt': 1.0, 'CI': [1.18, 0.82]},
{'t': 74, 'Rt': 1.0, 'CI': [1.18, 0.81]},
{'t': 75, 'Rt': 0.99, 'CI': [1.17, 0.81]},
{'t': 76, 'Rt': 0.99, 'CI': [1.17, 0.8]},
{'t': 77, 'Rt': 0.99, 'CI': [1.17, 0.81]},
{'t': 78, 'Rt': 0.99, 'CI': [1.17, 0.81]},
{'t': 79, 'Rt': 1.0, 'CI': [1.18, 0.81]},
{'t': 80, 'Rt': 1.01, 'CI': [2.32, 0]},
{'t': 81, 'Rt': 1.58, 'CI': [2.63, 0.42]},
{'t': 82, 'Rt': 1.43, 'CI': [2.33, 0.45]},
{'t': 83, 'Rt': 1.3, 'CI': [2.1, 0.43]},
{'t': 84, 'Rt': 1.45, 'CI': [2.17, 0.68]},
{'t': 85, 'Rt': 1.69, 'CI': [2.32, 1.01]},
{'t': 86, 'Rt': 1.83, 'CI': [2.4, 1.24]},
{'t': 87, 'Rt': 1.77, 'CI': [2.28, 1.24]},
{'t': 88, 'Rt': 1.69, 'CI': [2.15, 1.21]},
{'t': 89, 'Rt': 1.64, 'CI': [2.06, 1.21]},
{'t': 90, 'Rt': 1.57, 'CI': [1.96, 1.16]},
{'t': 91, 'Rt': 1.51, 'CI': [1.87, 1.13]},
{'t': 92, 'Rt': 1.52, 'CI': [1.86, 1.17]},
{'t': 93, 'Rt': 1.53, 'CI': [1.85, 1.2]},
{'t': 94, 'Rt': 1.5, 'CI': [1.8, 1.19]},
{'t': 95, 'Rt': 1.46, 'CI': [1.75, 1.17]},
{'t': 96, 'Rt': 1.44, 'CI': [1.71, 1.16]},
{'t': 97, 'Rt': 1.43, 'CI': [1.68, 1.16]},
{'t': 98, 'Rt': 1.43, 'CI': [1.68, 1.18]},
{'t': 99, 'Rt': 1.42, 'CI': [1.66, 1.18]},
{'t': 100, 'Rt': 1.4, 'CI': [1.62, 1.17]},
{'t': 101, 'Rt': 1.39, 'CI': [1.61, 1.17]},
{'t': 102, 'Rt': 1.38, 'CI': [1.58, 1.17]},
{'t': 103, 'Rt': 1.37, 'CI': [1.56, 1.17]},
{'t': 104, 'Rt': 1.38, 'CI': [1.56, 1.18]},
{'t': 105, 'Rt': 1.37, 'CI': [1.55, 1.18]},
{'t': 106, 'Rt': 1.34, 'CI': [1.51, 1.16]},
{'t': 107, 'Rt': 1.32, 'CI': [1.49, 1.15]},
{'t': 108, 'Rt': 1.32, 'CI': [1.48, 1.15]},
{'t': 109, 'Rt': 1.3, 'CI': [1.46, 1.14]},
{'t': 110, 'Rt': 1.28, 'CI': [1.44, 1.13]},
{'t': 111, 'Rt': 1.28, 'CI': [1.43, 1.13]},
{'t': 112, 'Rt': 1.28, 'CI': [1.43, 1.14]},
{'t': 113, 'Rt': 1.28, 'CI': [1.42, 1.14]},
{'t': 114, 'Rt': 1.26, 'CI': [1.4, 1.13]},
{'t': 115, 'Rt': 1.26, 'CI': [1.4, 1.13]},
{'t': 116, 'Rt': 1.28, 'CI': [1.4, 1.15]},
{'t': 117, 'Rt': 1.28, 'CI': [1.4, 1.15]},
{'t': 118, 'Rt': 1.28, 'CI': [1.4, 1.15]},
{'t': 119, 'Rt': 1.27, 'CI': [1.39, 1.15]},
{'t': 120, 'Rt': 1.27, 'CI': [1.38, 1.15]},
{'t': 121, 'Rt': 1.27, 'CI': [1.38, 1.16]},
{'t': 122, 'Rt': 1.27, 'CI': [1.37, 1.16]},
{'t': 123, 'Rt': 1.27, 'CI': [1.37, 1.16]},
{'t': 124, 'Rt': 1.28, 'CI': [1.38, 1.17]},
{'t': 125, 'Rt': 1.28, 'CI': [1.38, 1.18]},
{'t': 126, 'Rt': 1.28, 'CI': [1.38, 1.19]},
{'t': 127, 'Rt': 1.29, 'CI': [1.38, 1.19]},
{'t': 128, 'Rt': 1.29, 'CI': [1.39, 1.2]},
{'t': 129, 'Rt': 1.31, 'CI': [1.39, 1.22]},
{'t': 130, 'Rt': 1.31, 'CI': [1.4, 1.23]},
{'t': 131, 'Rt': 1.32, 'CI': [1.4, 1.23]},
{'t': 132, 'Rt': 1.32, 'CI': [1.4, 1.24]},
{'t': 133, 'Rt': 1.33, 'CI': [1.4, 1.25]},
{'t': 134, 'Rt': 1.33, 'CI': [1.41, 1.26]},
{'t': 135, 'Rt': 1.33, 'CI': [1.4, 1.26]},
{'t': 136, 'Rt': 1.32, 'CI': [1.39, 1.25]},
{'t': 137, 'Rt': 1.33, 'CI': [1.4, 1.26]},
{'t': 138, 'Rt': 1.33, 'CI': [1.4, 1.27]},
{'t': 139, 'Rt': 1.33, 'CI': [1.39, 1.27]},
{'t': 140, 'Rt': 1.33, 'CI': [1.39, 1.26]},
{'t': 141, 'Rt': 1.32, 'CI': [1.38, 1.26]},
{'t': 142, 'Rt': 1.32, 'CI': [1.37, 1.26]},
{'t': 143, 'Rt': 1.31, 'CI': [1.37, 1.26]},
{'t': 144, 'Rt': 1.31, 'CI': [1.36, 1.25]},
{'t': 145, 'Rt': 1.31, 'CI': [1.36, 1.25]},
{'t': 146, 'Rt': 1.31, 'CI': [1.36, 1.26]},
{'t': 147, 'Rt': 1.31, 'CI': [1.36, 1.26]},
{'t': 148, 'Rt': 1.3, 'CI': [1.35, 1.26]},
{'t': 149, 'Rt': 1.29, 'CI': [1.34, 1.25]},
{'t': 150, 'Rt': 1.28, 'CI': [1.47, 1.08]},
{'t': 151, 'Rt': 1.05, 'CI': [1.18, 0.91]},
{'t': 152, 'Rt': 0.96, 'CI': [1.07, 0.84]},
{'t': 153, 'Rt': 0.91, 'CI': [1.01, 0.81]},
{'t': 154, 'Rt': 0.9, 'CI': [0.99, 0.81]},
{'t': 155, 'Rt': 0.9, 'CI': [0.98, 0.81]},
{'t': 156, 'Rt': 0.88, 'CI': [0.96, 0.81]},
{'t': 157, 'Rt': 0.88, 'CI': [0.95, 0.8]},
{'t': 158, 'Rt': 0.87, 'CI': [0.94, 0.8]},
{'t': 159, 'Rt': 0.86, 'CI': [0.93, 0.79]},
{'t': 160, 'Rt': 0.85, 'CI': [0.91, 0.78]},
{'t': 161, 'Rt': 0.85, 'CI': [0.91, 0.79]},
{'t': 162, 'Rt': 0.86, 'CI': [0.91, 0.8]},
{'t': 163, 'Rt': 0.85, 'CI': [0.91, 0.79]},
{'t': 164, 'Rt': 0.85, 'CI': [0.9, 0.79]},
{'t': 165, 'Rt': 0.84, 'CI': [0.9, 0.79]},
{'t': 166, 'Rt': 0.84, 'CI': [0.9, 0.79]},
{'t': 167, 'Rt': 0.83, 'CI': [0.89, 0.78]},
{'t': 168, 'Rt': 0.83, 'CI': [0.88, 0.77]},
{'t': 169, 'Rt': 0.82, 'CI': [0.87, 0.77]},
{'t': 170, 'Rt': 0.82, 'CI': [0.87, 0.77]},
{'t': 171, 'Rt': 0.82, 'CI': [0.87, 0.77]},
{'t': 172, 'Rt': 0.82, 'CI': [0.87, 0.77]},
{'t': 173, 'Rt': 0.81, 'CI': [0.86, 0.77]},
{'t': 174, 'Rt': 0.81, 'CI': [0.86, 0.76]},
{'t': 175, 'Rt': 0.81, 'CI': [0.86, 0.76]},
{'t': 176, 'Rt': 0.81, 'CI': [0.85, 0.76]},
{'t': 177, 'Rt': 0.81, 'CI': [0.85, 0.76]},
{'t': 178, 'Rt': 0.81, 'CI': [0.86, 0.77]},
{'t': 179, 'Rt': 0.81, 'CI': [0.86, 0.76]},
{'t': 180, 'Rt': 0.81, 'CI': [0.85, 0.76]},
{'t': 181, 'Rt': 0.8, 'CI': [0.85, 0.76]},
{'t': 182, 'Rt': 0.8, 'CI': [0.85, 0.76]},
{'t': 183, 'Rt': 0.8, 'CI': [0.85, 0.76]},
{'t': 184, 'Rt': 0.8, 'CI': [0.84, 0.76]},
{'t': 185, 'Rt': 0.8, 'CI': [0.84, 0.75]},
{'t': 186, 'Rt': 0.79, 'CI': [0.84, 0.75]},
{'t': 187, 'Rt': 0.79, 'CI': [0.84, 0.75]},
{'t': 188, 'Rt': 0.79, 'CI': [0.84, 0.75]},
{'t': 189, 'Rt': 0.79, 'CI': [0.84, 0.75]},
{'t': 190, 'Rt': 0.79, 'CI': [0.83, 0.75]},
{'t': 191, 'Rt': 0.79, 'CI': [0.83, 0.75]},
{'t': 192, 'Rt': 0.79, 'CI': [0.83, 0.75]},
{'t': 193, 'Rt': 0.79, 'CI': [0.83, 0.74]},
{'t': 194, 'Rt': 0.78, 'CI': [1.21, 0.35]},
{'t': 195, 'Rt': 0.67, 'CI': [1.04, 0.29]},
{'t': 196, 'Rt': 0.72, 'CI': [1.05, 0.38]},
{'t': 197, 'Rt': 0.71, 'CI': [1.02, 0.4]},
{'t': 198, 'Rt': 0.64, 'CI': [0.93, 0.35]},
{'t': 199, 'Rt': 0.6, 'CI': [0.87, 0.32]},
{'t': 200, 'Rt': 0.62, 'CI': [0.88, 0.35]},
{'t': 201, 'Rt': 0.62, 'CI': [0.88, 0.37]},
{'t': 202, 'Rt': 0.61, 'CI': [0.86, 0.36]},
{'t': 203, 'Rt': 0.61, 'CI': [0.85, 0.37]},
{'t': 204, 'Rt': 0.61, 'CI': [0.84, 0.37]},
{'t': 205, 'Rt': 0.6, 'CI': [0.83, 0.37]},
{'t': 206, 'Rt': 0.6, 'CI': [0.82, 0.37]},
{'t': 207, 'Rt': 0.59, 'CI': [0.81, 0.37]},
{'t': 208, 'Rt': 0.58, 'CI': [0.8, 0.36]},
{'t': 209, 'Rt': 0.58, 'CI': [0.79, 0.35]},
{'t': 210, 'Rt': 0.58, 'CI': [0.79, 0.36]},
{'t': 211, 'Rt': 0.59, 'CI': [0.8, 0.37]},
{'t': 212, 'Rt': 0.59, 'CI': [0.8, 0.37]},
{'t': 213, 'Rt': 0.58, 'CI': [0.79, 0.36]},
{'t': 214, 'Rt': 0.58, 'CI': [0.78, 0.36]},
{'t': 215, 'Rt': 0.58, 'CI': [0.79, 0.37]},
{'t': 216, 'Rt': 0.58, 'CI': [0.78, 0.37]},
{'t': 217, 'Rt': 0.58, 'CI': [0.78, 0.37]},
{'t': 218, 'Rt': 0.58, 'CI': [0.79, 0.37]},
{'t': 219, 'Rt': 0.57, 'CI': [0.78, 0.36]},
{'t': 220, 'Rt': 0.56, 'CI': [0.76, 0.35]},
{'t': 221, 'Rt': 0.55, 'CI': [0.75, 0.35]},
{'t': 222, 'Rt': 0.55, 'CI': [0.75, 0.34]},
{'t': 223, 'Rt': 0.54, 'CI': [0.75, 0.34]}]

class Covid extends React.Component { 
    constructor(props) { 
        super(props)
        this.state = {
            main: null,
            pubs: null, 
            press: null,
            summary: null
        }
    }

    componentDidMount() {
        Promise.all([
            fetch(main).then((res) => res.text()).then((text) => {this.setState({main: text})}),
            fetch(pubs).then((res) => res.text()).then((text) => {this.setState({pubs: text})}),
            fetch(press).then((res) => res.text()).then((text) => {this.setState({press: text})}),
            fetch(summary).then((res) => res.text()).then((text) => {this.setState({summary: text})}),
        ])
    }

    content(key) { 
        return <ReactMarkdown source={this.state[key]} plugins={[gfm]} allowDangerousHtml/>
    }

    render = () => <div className = "fullwidth landing-right" style = {{height: "100%!important"}}>
    <Jumbotron fluid style = {{width:"100%", height:"30vh", backgroundImage: `url(${large_header})`, backgroundPosition: "top", opacity:0.6}} /> 
    <Container fluid  style={{ paddingLeft: "10vw", paddingRight: "10vw", paddingBottom: "15vh"}}>
    <Breadcrumb crumbs={["home", "research"]}/>
    <h1>Dynamics of Pandemics: Epidemiological Modeling for COVID-19</h1>
    <br/>
    {this.content("summary")}
    <Row>
        <Col> <h2>Publications + Talks</h2> {this.content("pubs")} </Col>
        <Col> <h2>Press</h2> {this.content("press")} </Col>
    </Row>
    <hr/>
    {this.content("main")}
    <Row>
    <Col>
    <ResponsiveContainer width="100%" height={400} >
    <ComposedChart data={I_data} margin={{top: 20, right: 80, bottom: 20, left: 20}}>
        <XAxis dataKey="t" label={{ value: "time", position: 'insideBottomRight', fontFamily: 'Libre Franklin'}} tick={false}/>
        <YAxis label={{ style: {textAnchor: "middle"}, value: "daily cases", angle: -90, position: 'left', fontFamily: 'Libre Franklin' }} ticks = {[0, 1500, 3000, 4000]}/>
        <CartesianGrid stroke='#f5f5f5'/>
        <Area type='monotone' dataKey='I_CI' fill='#82ca9d' stroke='#8884d8'/>
        <Line type='monotone' dataKey='I'    stroke='#82ca9d' dot={false}/>
      </ComposedChart>
      </ResponsiveContainer>
      <p style = {{"textAlign": "center"}}>Daily infection counts over time for an example pandemic. These case data are fed into a stochastic SIR model to estimate <i>R<sub>t</sub></i>.</p>
    </Col>
    <Col>
    <ResponsiveContainer width="100%" height={400} >
    <ComposedChart data={Rt_data} margin={{top: 20, right: 80, bottom: 20, left: 20}}>
        <XAxis dataKey="t" label={{ value: "time", position: 'insideBottomRight', fontFamily: 'Libre Franklin'}} tick={false}/>
        <YAxis label={{ style: {textAnchor: "middle"}, value: "reproductive rate", angle: -90, position: 'left', fontFamily: 'Libre Franklin'}} ticks = {[0, 1, 2, 3, 4]}/>
        <CartesianGrid stroke='#f5f5f5'/>
        <Area type='monotone' dataKey='CI' fill='rebeccapurple' stroke='grey' zorder={2} fillOpacity = {0.35}/>
        <Line type='monotone' dataKey='Rt' stroke='black' dot={false} zorder={1} strokeWidth = {1}/>
      </ComposedChart>
      </ResponsiveContainer>
      <p style = {{"textAlign": "center"}}>Estimate of reproductive rate (black) with 95% confidence interval (purple). Sharp changes in Bayesian estimates are due to simulated annealing corrections when case counts change rapidly.</p>
    </Col>
    </Row>


    <p>One of the quantities we can estimate from our stochastic model is the much-discussed and oft-misunderstood reproductive rate (<i>R<sub>t</sub></i>): the average number of additional infections a currently-infected person generates. When <i>R<sub>t</sub></i> is greater than 1, the number of infectious cases grows exponentially. This is generally seen as a bad thing. The goal of most pandemic responses has been to reduce the reproductive rate below 1, as discussed below.</p>

    <h2> Adaptive Control of Epidemics</h2>
    <p>For COVID-19, there are a number of responses that seem effective, including facemask usage mandates, rigorous hand-washing, and social distancing. The last measure reduces the contact rate and in turn the number of people infected by an already-infected person. Social distancing has been implemented through the use of lockdowns: shuttering non-essential services and establishments until the reproductive rate has fallen below 1. The economic cost of these measures is high - especially if applied at a national level when certain regions of the country are not facing significant infections. We propose an adaptive policy that is responsive to local concerns: if <i>R<sub>t</sub></i> in a district or county is below 1, socioeconomic activity can resume (cautiously), but if <i>R<sub>t</sub></i> is greater than 1, social distancing measures should stay in place until the pandemic is managed.</p>

    <p>Pandemics are nonlocal, however - when people travel between regions, they may reignite infection clusters in areas that previously had the pandemic under control. This is where the networked nature of our modeling comes in: if travel is allowed between regions (each represented by an SIR model), we can use historical travel data and current prevalence to estimate how many infected people from one region travel to another, and how that affects prevalence in each region. In addition to social distancing, then, an adaptive control policy regime needs travel restrictions as well - population flow should only be allowed between regions where <i>R<sub>t</sub></i> is less than 1, and we should probably require the regions to neighbor each other.</p>
    <p>We show through simulation that the adaptive policy regime is better at infection suppression, while allowing socioeconomic activity to resume safely. The tricky bit is whether governments have the political will or mettle to enforce a return to lockdown once measures have been released. And even if they do, will people comply with new restrictions? Pandemic fatigue is real, and I think a really interesting ground to target with behavioral "nudges". </p>

    {/* <Row>
    <Col md = {{"offset": 3, "span": 6}}>
    <ResponsiveContainer width="100%" height={400} >
    <ComposedChart data={Rt_data} margin={{top: 20, right: 80, bottom: 20, left: 20}}>
        <XAxis dataKey="t" label={{ value: "time", position: 'insideBottomRight', fontFamily: 'Libre Franklin'}} tick={false}/>
        <YAxis label={{ style: {textAnchor: "middle"}, value: "daily cases", angle: -90, position: 'left', fontFamily: 'Libre Franklin'}} ticks = {[0, 1, 2, 3, 4]}/>
        <CartesianGrid stroke='#f5f5f5'/>
        <Area type='monotone' dataKey='CI' fill='rebeccapurple' stroke='grey' zorder={2} fillOpacity = {0.35}/>
        <Line type='monotone' dataKey='Rt' stroke='black' dot={false} zorder={1} strokeWidth = {1}/>
      </ComposedChart>
      </ResponsiveContainer>
      <p style = {{"textAlign": "center"}}>Simulations of daily infection counts under adaptive vs non-adaptive control.</p>
    </Col>
    </Row> */}
    <h2>Optimal Vaccine Delivery</h2>
    <p>In addition to non-pharmaceutical interventions, vaccination policy is an important component of suppression measures. Given supply chain constraints, the number of available vaccines will likely not cover the entirety of a nation's population, and most countries have a finite budget for vaccination. How should societies allocate the finite supply of vaccines to save lives and reopen economies most effectively? </p>

    <p>We can use our SIR model with its bells and whistles to attack this question as well. Instead of partitioning a nation's population into just geographical segments and creating an SIR model for each geography, we can instead group people by occupation or bin them by ages and create an SIR model for each of these segments. In this case, instead of moving people between segments, we instead look at the contact structure between these groups. For each segment, we can calculate the maximum value of <i>R<sub>t</sub></i> observed for that segment; the number of infections prevented by vaccinating a member of that segment is given by (1 + <i>R<sub>t</sub></i>). If we want to minimize infections, we can just look at the segment for which <i>R<sub>t</sub></i> reaches a maximum and then vaccinate away! We could also use a different prioritization for occupation segments - for example, prioritizing the vaccination of healthcare workers and other critical service providers before other occupation segments.</p>

    <p>A natural reservation price also falls out of this approach - by using the value of a statistical life multiplied by lives saved, we can estimate the social benefit of vaccination and let countries buy vaccines up until the point the cost of an additional vaccine outweighs the benefits. I'm a little torn about using the value of a statistical life: on the one hand, I don't like putting dollar amounts on humans, but on the other hand, it makes me feel like some sort of neoliberal deity. In any case, societies can choose which policy aim to prioritize, and our segmentation approach will reveal the optimal allocation given the stated goal.</p>

    </Container>
    </div>
}

export default Covid;